<template>
    <div class="home-page">
        <div class="container">
            <div class="content">
                <div class="row align-items-center justify-content-center pt-4 pb-4">
                    <div class="col-md-6">
                        <a class="btn btn-link" href="/profile"><img src=".././assets/images/backarrow.svg"></a>
                    </div>
                    <div class="col-md-6 text-end dir-rtl text-primarydark">
                        <h1 class="text-primarydark fw-bold nanosans">
                            الدروس المكتملة
                        </h1>
                    </div>
                </div>
                <div class="row loadingcate-m" v-if="loadingDiv">
                    <div class="col-12 col-md-12">
                        <div></div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div></div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div></div>
                    </div>
                </div>
                <div class="row dir-rtl pb-5" v-else>
                    <!-- {{ filteringCategories }} -->
                    <div class="col-12">
                        <ul class="category-listp nanosans mt-0" v-if="filteredCategories.length > 0">
                            <li v-for="(item) in filteredCategories" :key="item.id">
                                <router-link :to="{ name: 'Lessons', params: { id: item.id } }"
                                    :class="item.user_completed_lessons === item.total_lessons ? 'completed' : ''"
                                    @click="getName(item.name)">
                                    <div class="w-80">
                                        <img :src="item.image" width="52">
                                        {{ item.name }}
                                    </div>
                                    <div class="w-20 text-start">
                                        {{ toArabicNumber(item.user_completed_lessons) }}/{{ toArabicNumber(item.total_lessons) }}
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                        <h4 class="text-center pt-5 mt-5" v-else>
                            لم تكتمل أي فئة بعد
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'HomeMain',
    data() {
        return {
            progress: '',
            profileName: '',
            loadingDiv: true,
            profileGet: [],
            categories: [],
            completelesson: '',
            totalesson: '',
            youlessons: [
                {
                    name: 'محادثة',
                    totalesson: 2,
                    completelesson: '1',
                    image: require('.././assets/images/hand-ico.svg'),
                    progress: '30%'
                },
                {
                    name: 'علوم',
                    totalesson: 2,
                    completelesson: 0,
                    image: require('.././assets/images/education.svg'),
                    progress: '50%'
                }

            ],
            youachiev: [
                {
                    name: 'محادثة',
                    totalesson: 2,
                    completelesson: '1',
                    image: require('.././assets/images/hand-ico.svg'),
                    progress: '30%',
                    status: 'completed'
                },
                {
                    name: 'علوم',
                    totalesson: 2,
                    completelesson: 0,
                    image: require('.././assets/images/education.svg'),
                    progress: '50%',
                    status: 'completed'
                }

            ],
        }
    },
    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.profileName = dataget.name
        }
    },
    computed: {
        filteredCategories() {
            return this.categories.filter((value) => {
                return (value.user_completed_lessons !== 0 || value.total_lessons !== 0) &&
                    (value.user_completed_lessons !== value.total_lessons || value.user_completed_lessons === 0 || value.user_completed_lessons === value.total_lessons) && (value.user_completed_lessons !== 0);
            });
        },
        filteringCategories() {
            return this.categories.filter((value) => {
                const completedLessons = parseInt(value.user_completed_lessons);
                const totalLessons = parseInt(value.total_lessons);

                return completedLessons === totalLessons && completedLessons !== 0;
            });
        },
    },
    methods: {
        toArabicNumber(text) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return text
                .toString()
                .split('')
                .map(char => {
                    // Convert only numeric characters
                    if (/\d/.test(char)) {
                        return arabicDigits[char];
                    }
                    // Keep non-numeric characters (like % and .)
                    return char;
                })
                .join('');
        },
        async fetchData() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/word-of-the-day', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                        // 'ngrok-skip-browser-warning': '20349'
                    }
                });

                this.profileGet = response.data;
                this.progress = response.data.fluency_percentage + '%';
                this.categories = this.profileGet.categories;

                // sessionStorage.setItem('wordDay-english', this.profileGet.word_of_the_day.word_english);
                // console.log('hdjhdsj', this.categories);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },
        getName(cname) {
            localStorage.setItem('categoryname', cname);
        },
        clickdisab(event) {
            if (this.youachiev.status == 'completed') {
                event.target.disabled = true
            }
        },

        crossGo() {
            window.history.back();
        }
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>