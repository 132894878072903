<template>
    <div>
        <!-- {{ timerCount }} -->
        <!-- {{ popCount }} -->
        <div class="text-center wordpopup h-100" v-if="thankspop">
            <div class="container h-100">
                <div class="row align-items-center d-flex">
                    <div class="text-end mt-5"><button type="button" class="btn btn-link" @click="closepop"><img
                                src="../.././assets/images/cross-icon-light.svg"> </button></div>
                    <div class="col-12">
                        <h2 class="text-white fw-bold nanosans pt-3 mb-3 dir-rtl">هل تذكرت كلمة اليوم؟</h2>
                        <!-- <h1 class="text-secondary fw-bold pt-3 mb-3">اكتبها باللغة الإنجليزية للحصول على مكافأة!</h1> -->
                        <h3 class="text-secondary fw-normal nanosans pt-3 mb-3 dir-rtl">اكتبها باللغة الإنجليزية للحصول على
                            مكافأة!
                        </h3>

                    </div>
                    <div class="col-lg-7 mx-auto" v-if="!successPage">
                        <h3 class="text-white fw-normal nanosans pt-3 mb-3">{{ errorMessage }}</h3>
                        <Form @submit="login">
                            <div class="input-group mb-3">
                                <Field type="text" class="form-control text-center" :class="hitclass" placeholder="اكتب هنا" v-model="wordofday"
                                    name="word" :rules="isRequired" @paste.prevent />
                                <ErrorMessage name="word" class="error w-100 text-danger" />
                            </div>
                            <img src="../.././assets/images/goldencat.svg" class="mb-0 mt-4 word-pop-img">

                            <button type="submit"
                                class="btn btn-main btn-lg py-3 w-100 mt-5 dir-rtl btn-word position-relative"
                                :disabled="loadingDiv">
                                <div class="spinner" v-if="loadingDiv"></div>
                                يُقدِّم
                            </button>
                        </Form>
                    </div>
                    <div class="col-lg-7 mx-auto" v-else>
                        <h2 class="text-gold fw-bold nanosans pt-3 mb-3">إجابتك صحيحة وسوف تتم مكافأتك</h2>
                        <img src="../.././assets/images/goldencat.svg" class="mt-5">
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';

export default {
    name: 'WordOfDay',
    props: ['globalTimer'],
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            timerCount: 0,
            timerEnabled: true,
            thankspop: false,
            wordofday: '',
            successPage: false,
            errorMessage: '',
            loadingDiv: false,
            popCount: parseInt(localStorage.getItem('buttonClickCount')) || 0,
            hitclass: ''
            // disablebtn: true,
        }
    },
    mounted() {
        this.poppupShow();
        
        // var is_modal_show = sessionStorage.getItem('alreadyShow');
        // console.log(is_modal_show);
    },
    // created(){
    //     this.closepop();
    // },
    methods: {
        isRequired(value) {
            if (!value) {
                return 'هذه الخانة مطلوبه';
            }
            return true;
        },
        poppupShow() {
            var is_modal_show = sessionStorage.getItem('alreadyShow');
            var is_success = sessionStorage.getItem('wordpopup');
            var is_answered = sessionStorage.getItem('wordanswered');
            // console.log('andr aya');  
            if (this.globalTimer < 100 || is_modal_show === 'true' || is_success === 'true' || is_answered === 'true') {
                // console.log('nhi open hoga');
                setTimeout(() => {
                    this.timerCount = this.globalTimer;
                    this.poppupShow();
                    this.thankspop = false;
                }, 1000);
            } else if (this.globalTimer === 100 || is_modal_show === 'false' && is_success === 'false' || is_answered === 'false') {
                // console.log('open hoga');   
                this.$nextTick(() => {
                    this.thankspop = true;
                });

                // // Mark the modal as already shown
                // sessionStorage.setItem('alreadyShow', 'true');
            }

        },
        closepop() {
            this.popCount++;
            localStorage.setItem('buttonClickCount', this.popCount.toString());
            console.log('count pop', this.popCount);
            if (this.popCount === 3) {
                this.$emit('reset-global-timer');
                sessionStorage.setItem('alreadyShow', 'true');
            }

            this.thankspop = false;

        },
        async login() {
            this.loadingDiv = true;
            try {
                // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
                const response = await axios.post('https://backend.nadyalloughat.com/api/word-of-the-day/submit', {
                    // country: this.dialCode,
                    word_of_the_day: this.wordofday,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                // console.log('Response:', response.data.message);
                if (response.status === 200 || response.status === 201) {
                    // Login successful, handle the response (e.g., store user data)
                    this.successPage = true;
                    sessionStorage.setItem('wordpopup', 'true');
                    this.hitclass = ''
                } else {
                    // Login failed, display an error message
                    console.error('data failed:', response.data.message);
                    this.errorMessage = response.data.message;
                }
            } catch (error) {
                this.errorMessage = 'الإجابة غير صحيحة';
                this.hitclass = 'errorside'
            } finally {
                this.loadingDiv = false;
            }
        }
    }
}
</script>