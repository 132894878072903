<template>
    <div class="home-page profile-main">
        <div class="container">
            <header class="mb-md-2 mt-md-2">
                <HeaderNav :data-intro-step1="dataIntro" :data-intro-step2="dataHisaab" :data-intro-step3="dataRaisi"
                    :data-intro-steps1="dataIntro" :data-intro-steps2="dataHisaab" :data-intro-steps3="dataRaisi" />
            </header>
        </div>
        <div class="content blue-bg">
            <div class="container">
                <div class="row dir-rtl pt-3 d-block d-md-none">
                    <div class="col-4">
                        <img src=".././assets/images/logowhite.svg" alt="">
                    </div>
                </div>
                <div class="row align-items-top justify-content-center">
                    <div class="col-md-6 position-relative">
                        <div class="text-start pt-4">
                            <router-link to="/notifications" class="setting position-relative" @click="notibell">
                                <!-- {{ email }} -->
                                <span class="warning-notify" v-if="notifywarn"></span>
                                <img src=".././assets/images/notification-icon.svg">
                                <div class="tooltip">
                                    الإشعارات
                                </div>
                            </router-link>
                        </div>
                        <div class="d-block d-md-none text-end">
                            <h5 class="text-white pt-0 mt-2">
                                مرحبا
                            </h5>
                            <h1 class="text-white fw-bold nanosans">{{ profileName }}</h1>
                        </div>

                        <WordOfTheDay :worddaydata="profileGet" />
                        <!-- word of the day component -->

                    </div>
                    <div class="col-md-6 loadingprofile" v-if="loadingDiv">
                        <div class="proy-pic"></div>
                        <div class="wategae"></div>
                        <div class="wategae"></div>
                        <div class="wategae"></div>
                    </div>
                    <div class="col-md-6 dir-rtl" v-else>
                        <h5 class="text-white pt-5 mt-2 d-none d-md-block">
                            مرحبا
                        </h5>
                        <h1 class="text-white fw-bold nanosans d-none d-md-block">{{ profileName }}</h1>
                        <div class="mt-3 mt-md-5" :data-intro-step4="dataIntroprog">
                            <h2 class="dir-rtl prohead text-white nanosans">
                                <img src=".././assets/images/uk-flag.png"> <span>بطلاقة</span> {{
                                    toArabicNumber(progresstext) }}
                            </h2>
                            <div class="progress progress-secondry dir-rtl">
                                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                                    aria-valuemax="100" :style="{ width: progress }"></div>
                            </div>
                        </div>
                        <div class="badges-area mt-3" :data-intro-step5="dataIntrobedge">
                            <router-link to="/allbadges"
                                class="btn btn-link text-secondary fw-bold w-100 nanosans mt-3 text-end"
                                v-if="achivedBadges.length > 0">
                                عرض المزيد
                            </router-link>
                            <router-link to="/allbadges"
                                class="btn btn-link text-secondary fw-bold w-100 nanosans mt-3 text-end" v-else>
                                عرض الشارات
                            </router-link>
                            <ul v-if="achivedBadges.length > 0">
                                <li v-for="badges in achivedBadges" :key="badges.id">
                                    <a href="/allbadges"><img :src="badges.image" alt=""> </a>
                                </li>
                                <!-- <li><a href="#"><img src="../assets/images/badge-1.png" alt=""> </a> </li>
                                <li><a href="#"><img src="../assets/images/badge-1.png" alt=""> </a> </li> -->
                            </ul>
                            <ul v-else>
                                <li class="text-white">ابدأ الدرس للبدء في الحصول على الشارات</li>
                            </ul>
                        </div>
                    </div>


                </div>

                <div class="row dir-rtl pb-5 d-none">
                    <div class="col-md-6">
                        <h2 class="dir-rtl prohead text-primarydark pt-5 pb-3 nanosans">
                            دروسك
                        </h2>

                        <ul class="category-listp nanosans mt-0">
                            <li v-for="(value) in filteredCategories" :key="value.id">
                                <router-link :to="{ name: 'Lessons', params: { id: value.id } }"
                                    @click="getName(value.name)">
                                    <div class="w-80">
                                        <img :src="value.image" width="52">
                                        {{ value.name }}
                                    </div>
                                    <div class="w-20 text-start">
                                        {{ value.user_completed_lessons }}/{{ value.total_lessons }}
                                        <span class="progress-verti">
                                            <span :style="{ 'height': value.progress }"></span>
                                        </span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <h2 class="dir-rtl prohead text-primarydark pt-5 pb-3 nanosans">
                            إنجازاتك
                        </h2>
                        <ul class="category-listp nanosans mt-0">
                            <li v-for="(item) in filteringCategories" :key="item.id" @click="getName(item.name)">
                                <router-link :to="{ name: 'Lessons', params: { id: item.id } }" class="completed">
                                    <div class="w-80">
                                        <img :src="item.image" width="52">
                                        {{ item.name }}
                                    </div>
                                    <div class="w-20 text-start">
                                        <span class="fontw-400">مكتمل</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <uniteSlide :data-intro-step6="unitsIntro" />
            <!-- Unit/chapter slider component -->
        </div>
    </div>
</template>
<script>
import HeaderNav from '.././components/common/HeaderNew.vue';
import axios from 'axios';
import introJs from 'intro.js';
// import walkCatDirective from '@/directives/v-walk-cat';
import uniteSlide from '@/components/UniteSlide.vue';
import WordOfTheDay from '@/components/WordOfDay.vue';

export default {
    name: 'HomeMain',
    components: {
        HeaderNav,
        uniteSlide,
        WordOfTheDay
    },
    data() {
        return {
            progress: '',
            profileName: '',
            profileGet: [],
            categories: [],
            // unitData: [],
            completelesson: '',
            totalesson: '',
            // unitload: true,
            achivedBadges: [],
            loadingDiv: true,
            progresstext: '',
            globalTimer: 0,
            notifywarn: !!sessionStorage.getItem('lastNotificationDate'),
            dataIntro: 'اكتشف فئاتنا المتنوعة وتابع تقدمك حسب رغبتك عبر هذه الفئات',
            dataHisaab: 'عدّل اسمك وصورتك، تابع تقدمك، أكمل درسك الحالي، واستعرض الشارات التي حصلت عليها',
            dataRaisi: 'تتبّع تقدمك، شاراتك، ودروسك بما فذلك درسك الحالي على الصفحة الرئيسية',
            unitsIntro: 'ابدأ رحلتك بالدرس الأول، واستمتع باكتشاف جميع الفصول وافتح القسم التالي مع كل قسم تنجزه!',
            dataIntroprog: 'تابع مسارك في رحلتك لتعلم الإنجليزية',
            dataIntrobedge: 'أكمل ملفك بإضافة صورتك الشخصية وعنوان بريدك الإلكتروني لتحصل على أول شاراتك',
            seenTutorial: 1,
        }
    },
    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.profileName = dataget.name
        }


    },
    computed: {
        filteredCategories() {
            return this.categories.filter((value) => value.user_completed_lessons !== value.total_lessons);
        },
        filteringCategories() {
            return this.categories.filter((value) => value.user_completed_lessons === value.total_lessons && value.total_lessons != 0);
        },
    },
    methods: {
        toArabicNumber(text) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return text
                .toString()
                .split('')
                .map(char => {
                    // Convert only numeric characters
                    if (/\d/.test(char)) {
                        return arabicDigits[char];
                    }
                    // Keep non-numeric characters (like % and .)
                    return char;
                })
                .join('');
        },
        startIntro() {
            const intro = introJs();
            intro.setOptions({
                steps: [
                    {
                        element: '[data-intro-step1]',
                        intro: this.dataIntro,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step2]',
                        intro: this.dataHisaab,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step3]',
                        intro: this.dataRaisi,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step4]',
                        intro: this.dataIntroprog,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step5]',
                        intro: this.dataIntrobedge,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step6]',
                        intro: this.unitsIntro,
                        disableInteraction: true,
                    },

                ],
                showProgress: true,
                exitOnOverlayClick: false,
                exitOnEsc: false,
                showBullets: false,
                nextLabel: 'التالي', // Customize the Next button text
                prevLabel: 'السابق', // Customize the Back button text
                // skipLabel: 'تخطي', // Customize the Skip button text
                doneLabel: 'انتهاء', // Customize the Done button text

            });
            intro.start();
        },
        startIntrom() {
            const intro = introJs();
            intro.setOptions({
                steps: [
                    {
                        element: '[data-intro-steps1]',
                        intro: this.dataIntro,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-steps2]',
                        intro: this.dataHisaab,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-steps3]',
                        intro: this.dataRaisi,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step4]',
                        intro: this.dataIntroprog,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step5]',
                        intro: this.dataIntrobedge,
                        disableInteraction: true,
                    },
                    {
                        element: '[data-intro-step6]',
                        intro: this.unitsIntro,
                        disableInteraction: true,
                    },
                ],
                showProgress: true,
                exitOnOverlayClick: false,
                exitOnEsc: false,
                showBullets: false,
                nextLabel: 'التالي', // Customize the Next button text
                prevLabel: 'السابق', // Customize the Back button text
                // skipLabel: 'تخطي', // Customize the Skip button text
                doneLabel: 'انتهاء', // Customize the Done button text
            });
            intro.start();
        },

        async fetchUpdateProfile() {
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
                    // country: this.dialCode,
                    has_seen_tutorial: this.seenTutorial,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    // Login successful, handle the response (e.g., store user data)
                    // this.successMessage = true;
                    localStorage.setItem('userData', JSON.stringify(response.data.user));

                } else {

                    this.errorMessage = response.errors;
                }
            } catch (error) {
                console.error('Error during login:', error);
            } finally {
                this.loadingDiv = false;
            }
        },

        notibell() {
            sessionStorage.removeItem('lastNotificationDate')
        },
        playsoundw(name) {
            const msg = new SpeechSynthesisUtterance();
            msg.text = name;
            msg.volume = 1.0;
            msg.pitch = 1.0;
            msg.rate = 0.8;
            msg.lang = 'en-GB';

            const voices = window.speechSynthesis.getVoices();
            const desiredVoice = voices.find(voice => voice.name === 'Google UK English Female');
            msg.voice = desiredVoice;

            // Add a class to another div on speech start
            msg.onstart = function () {
                const otherDiv = document.getElementById('englishwording'); // replace 'otherDiv' with your div's actual ID
                otherDiv.classList.add('textclip'); // replace 'yourClassName' with your desired class name
            };

            window.speechSynthesis.speak(msg);

            // Remove the class after 2 seconds
            setTimeout(function () {
                const otherDiv = document.getElementById('englishwording'); // replace 'otherDiv' with your div's actual ID
                otherDiv.classList.remove('textclip'); // replace 'yourClassName' with your class name
            }, 2000);
        },
        getName(catename) {
            sessionStorage.setItem('categoryname', catename);
        },
        async fetchData() {
            try {
                const responsew = await axios.get('https://backend.nadyalloughat.com/api/word-of-the-day', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });

                this.handleApiResponse(responsew.data);

                const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                const userhasSeen = JSON.parse(localStorage.getItem('userData'));
                const SeenHas = userhasSeen.has_seen_tutorial;
                // let isRouteChanged = false;

                // this.$watch('$route', () => {
                //     isRouteChanged = true;
                // });

                if (!isMobile) {

                    setTimeout(() => {
                        const hasSeenIntro = localStorage.getItem('hasSeenIntro');
                        if (this.$route.path === '/homepage' && !hasSeenIntro && SeenHas !== 1) {
                            this.startIntro();
                            this.fetchUpdateProfile();
                            localStorage.setItem('hasSeenIntro', 'true'); // Mark as seen
                        }
                    }, 1000);
                }
                if (isMobile) {
                    setTimeout(() => {
                        const hasSeenIntro = localStorage.getItem('hasSeenIntro');
                        if (this.$route.path === '/homepage' && !hasSeenIntro && SeenHas !== 1) {
                            this.startIntrom();
                            this.fetchUpdateProfile();
                            localStorage.setItem('hasSeenIntro', 'true'); // Mark as seen
                        }
                    }, 1000);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                } else {
                    this.loadingDiv = false; // Set isLoading to false on error
                }
            }
        },

        handleApiResponse(data) {
            this.profileGet = data;
            this.progress = data.fluency_percentage + '%';
            this.progresstext = '%' + data.fluency_percentage;
            this.categories = this.profileGet.categories;
            this.achivedBadges = data.achieved_badges;

            if (this.achivedBadges.length > 0) {

                this.dataIntrobedge = 'استعرض إنجازاتك والشارات المميزة التي حصلت عليها حتى الآن!';
            } else {
                this.dataIntrobedge = 'أكمل ملفك بإضافة صورتك الشخصية وعنوان بريدك الإلكتروني لتحصل على أول شاراتك';
            }
            // console.log(this.profileGet.answered);
            sessionStorage.setItem('wordanswered', this.profileGet.answered);

            const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

            if (this.profileGet.word_of_the_day !== null) {
                if (localStorage.getItem('lastResetDate') !== today) {
                    this.resetValues();
                    localStorage.setItem('lastResetDate', today);

                }
            } else {
                this.initializeValues();
            }

            this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
        },

        resetValues() {
            sessionStorage.setItem('alreadyShow', 'false');
            sessionStorage.setItem('wordpopup', 'false');
            sessionStorage.setItem('globalTimer', '0');
            localStorage.setItem('buttonClickCount', '0');
            this.globalTimer = 0;
        },

        initializeValues() {
            const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

            if (localStorage.getItem('lastInitializationDate') !== today) {
                sessionStorage.setItem('alreadyShow', 'true');
                sessionStorage.setItem('wordpopup', 'true');
                // Add any other initialization logic here
                localStorage.setItem('lastInitializationDate', today);
            }
        },
        // getPage(name, discription, id) {
        //     sessionStorage.setItem('uname', name);
        //     sessionStorage.setItem('udiscription', discription);
        //     sessionStorage.setItem('uid', id);
        // },

        clickdisab(event) {
            if (this.youachiev.status == 'completed') {
                event.target.disabled = true
            }
        }
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>