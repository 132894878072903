import { createRouter, createWebHistory } from 'vue-router';

import Home from './views/HomeMain.vue';
import loginInfo from './views/loginInfo.vue';
import Login from './views/LogIn.vue';
import Signup from './views/SignUp.vue';
import Category from './views/Category.vue';
import HomePage from './views/HomePage.vue';
import Lessons from './views/Lessons.vue';
import Lessonstart from './views/Lessonstart.vue';
import Quiznow from './views/quiz/QuizNow.vue';
import Profile from './views/Profile.vue';
import Setting from './views/AccountSet.vue';
import PrivacyPolicy from './views/common/PrivacyPolicy.vue';
import TermsCondition from './views/common/TermsCondition.vue';
import ForgetPass from './views/ForgetPass.vue';
import CompleteLess from './views/Completed.vue';
import ChapterMap from './views/ChapterMap.vue';
import Notificatiions from './views/Notificatiions.vue';
import ErrorPage from './components/ErrorPage.vue';
import allBadges from './views/allBadges.vue';
import QuizStart from './views/quiz/QuizStart.vue';
import Unsubs from './views/unsubscribe/Unsubribe.vue';
import Unsubsopt from './views/unsubscribe/UnsubribeOption.vue';
import Deletaccount from './views/unsubscribe/DeleteAccount.vue';




const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home Page',
            description: 'Home Page',
        },
    },
    {
        path: '/login-info',
        name: 'LoginInfo',
        component: loginInfo,
        meta: { requiresAuth: true },
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        // meta: { requiresAuth: true },
    },
    {
        path: '/policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: { requiresAuth: true },
    },
    {
        path: '/terms',
        name: 'TermsCondition',
        component: TermsCondition,
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/forgetpassword',
        name: 'ForgetPass',
        component: ForgetPass,
    },
    {
        path: '/completed',
        name: 'Completed',
        component: CompleteLess,
        meta: { requiresAuth: true },
    },
    {
        path: '/categories',
        name: 'Category',
        component: Category,
        meta: { requiresAuth: true },
    },
    {
        path: '/homepage',
        name: 'HomePage',
        component: HomePage,
        meta: { requiresAuth: true },
    },
    {
        path: '/lessons/:id',
        name: 'Lessons',
        component: Lessons,
        meta: {
            requiresAuth: true,
            cameFromCategoryPage: true,
        },
    },
    {
        path: '/lessonstart/:id',
        name: 'Lessonstart',
        component: Lessonstart,
        meta: { requiresAuth: true },
    },
    {
        path: '/quiz/:id',
        name: 'Quiznow',
        component: Quiznow,
        meta: { requiresAuth: true },
    },
    {
        path: '/quiz-start/:uid/:cid',
        name: 'QuizStart',
        component: QuizStart,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true },
    },
    {
        path: '/unsubscribe',
        name: 'Unsubs',
        component: Unsubs,
        meta: { requiresAuth: true },
    },
    {
        path: '/unsubscriboptions',
        name: 'Unsubsopt',
        component: Unsubsopt,
        meta: { requiresAuth: true },
    },
    {
        path: '/delete-account',
        name: 'Deletaccount',
        component: Deletaccount,
        meta: { requiresAuth: true },
    },
    {
        path: '/setting',
        name: 'Setting',
        component: Setting,
        meta: { requiresAuth: true },
    },
    {
        path: '/road-map/:id',
        name: 'RoadMap',
        component: ChapterMap,
        meta: { requiresAuth: true },
    },
    {
        path: '/notifications',
        name: 'Notificatiions',
        component: Notificatiions,
        meta: { requiresAuth: true },
    },
    {
        path: '/allbadges',
        name: 'All Badges',
        component: allBadges,
        meta: { requiresAuth: true },
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'ErrorView',
        component: ErrorPage,
    },
];


const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
    base: '/', // Set the base property to BASE_URL
    scrollBehavior() {
        return new Promise((resolve) => {
            resolve({ left: 0, top: 0 })
        })
    } // Always scroll start from top

});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Nadyalloughat';
    const isLoggedIn = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if the user is logged in

        if (!isLoggedIn) {
            // Redirect to the login page if not logged in
            next('/login');
        } else {
            next(); // Proceed to the protected route
        }
    } else {
        if (isLoggedIn && (to.path === '/login' || to.path === '/signup')) {
            next('/homepage');
        } else {
            next();
        }
    }

});
// Check authantication code

export default router;