<template>
    <div class="home-page">
        <div class="container">
            <header>
                <HeaderNav />
            </header>
            <div class="content pe-4 ps-4 pe-md-0 ps-md-0">

                <div class="row align-items-center justify-content-center mt-4 mt-md-4">
                    <div class="col-3 d-block d-md-none">
                        <button class="btn btn-link" @click="crossGo"><img
                                src=".././assets/images/backarrow.svg"></button>
                    </div>
                    <div class="col-md-3 d-md-block d-none">
                        <img src=".././assets/images/cat-language-app-loading-cop.gif" class="img-fluid" />
                    </div>
                    <div class="col-9 text-end dir-rtl text-primarydark mt-2 mt-md-5">
                        <h1 class="text-primarydark fw-bold nanosans">
                            الدرس {{ lessonnumer }} </h1>
                    </div>
                </div>

                <div class="row loadingleson-s mt-4 mb-4" v-if="loadingDiv">
                    <div class="col-6 col-md-3">
                        <div></div> <span></span>
                    </div>
                    <div class="col-6 col-md-3">
                        <div></div> <span></span>
                    </div>
                    <div class="col-6 col-md-3">
                        <div></div> <span></span>
                    </div>
                    <div class="col-6 col-md-3">
                        <div></div> <span></span>
                    </div>
                </div>
                <div class="row pb-5 dir-rtl mt-4" v-if="!loadingDiv">
                    <!-- <div class="col-12">
                        <h2 class="text-primarydark fw-bold nanosans text-end mb-4">
                            خلاصة الدرس
                        </h2>
                    </div> -->
                    <!-- {{ lesson }} -->
                    <div class="col-6 col-md-3" v-for="(lessons, index) in lesson" :key="index">
                        <!-- {{ lessons }} -->
                        <div class="box-prev dir-ltr">
                            <div><img :src="lessons.image"></div>
                            <p class="dir-rtl">{{ lessons.name_arabic }}</p>
                            
                            <p class="english-starles"><span :class="{ 'textclip': otherDiv === index + 'textclipped' }">{{
                                lessons.name_english }}</span> <button class="btn btn-link"
                                    @click="playsoundw(index, lessons.english_tts_audio)"
                                    v-if="lessons.english_tts_audio"><img src="../assets/images/sound-wplay.svg"
                                        alt=""></button></p>
                        </div>
                    </div>

                </div>
                <div class="row dir-rtl pb-5">
                    <div class="col-md-12 pb-4 pb-md-0">
                        <router-link :to="{ name: 'Quiznow', params: { id: id } }" @click="getDate"
                            class="btn btn-main btn-lg py-3 w-100 nanosans">
                            ابدأ الدرس
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderNav from '.././components/common/HeaderNav.vue';
import startLevel from '../assets/audio/startbutton.wav';

import axios from 'axios';
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
    name: 'HomeMain',
    setup() {
        const route = useRoute();
        const id = computed(() => route.params.id);
        return {
            id,
        };
    },
    components: {
        HeaderNav
    },
    data() {
        return {
            lessonnumer: localStorage.getItem('lessonNumber') || '',
            lesson: [],
            loadingDiv: true,
            currentAudio: null,
            otherDiv: null,
        };
    },
    mounted() {
        this.fetchData();

        if(!this.lessonnumer){
            this.$router.push({ name: 'Category' });
        }

    },
    methods: {
        playsoundw(index, sound) {
            // console.log(index);
            
            if (sound) {
                // console.log('https://backend.nadyalloughat.com' + sound);
                if (this.currentAudio && !this.currentAudio.paused){
                    this.currentAudio.pause();
                    this.currentAudio.currentTime = 0;
                }

                this.currentAudio = new Audio(sound)
                this.currentAudio.play();
                
                this.otherDiv = index + 'textclipped';
            }

            setTimeout(() => {
                this.otherDiv = null; // Remove the class after 2 seconds
            }, 2000);

        },
        getDate() {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            sessionStorage.setItem('startquiz', dateTime);

            
            var audiostart = new Audio(startLevel)
            audiostart.play();
        },
        crossGo() {
            window.history.back();
        },
        async fetchData() {
            const lessonId = this.$route.params.id;
            // console.log('lessone id', lessonId)
            try {
                const response = await axios.get('https://backend.nadyalloughat.com/api/category-lesson-highlights/' + lessonId, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',

                    }
                });


                this.lesson = response.data;
                // console.log('lessons now', this.lesson)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },
    },
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>