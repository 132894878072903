<template>
    <div>
        <!-- {{ succesmessage }} -->
        <div class="loadoverlay" v-if="loadingDivnew">
            <div class="spinner"></div>
        </div>
        <div class="alert alert-success" v-if="sucmes === true && this.errormessage === ''">{{ message }}</div>
        <div class="alert alert-danger" v-if="this.errormessage != ''">{{ errormessage }}</div>
        <Form @submit.prevent="pincheck">

            <div class="input-group mb-5">
                <div class="text-center">
                    <div ref="otpCont">
                        <input class="digit-box" type="text" v-for="(el, ind) in digitCount" :key="el + ind"
                            v-model="digits[ind]" :autofocus="ind == 0" maxlength="1" inputmode="numeric"
                            pattern="[0-9]" @keydown="handleKeyDown($event, ind)"
                            :class="{ bounce: digits[ind] !== null }" placeholder="-" />
                    </div>
                </div>
            </div>

            <div class="resend-otp text-center">
                <span @click="resendotp" v-if="disabledlink">لم تتلق الرمز؟ <strong>إعادة إرسال الرمز</strong></span>
                <span v-else>إعادة إرسال الرمز (معطل لمدة {{ remainingTime }} ثانية)</span>
            </div>

            <div class="input-groupb input-groupbb">
                <button type="submit" class="btn btn-main btn-lg py-3 w-100 mt-4 nanosans position-relative login-btn"
                    :disabled="loadingDiv || disablebtn">
                    <div class="spinner" v-if="loadingDiv"></div>
                    تأكيد
                </button>
                <div class="input-pera text-center nanosans mt-4 dir-rtl">
                    <h6 class="text-primarydark fw-semibold">سعر الخدمة ٢.٢٥ درهم في اليوم بعد يوم واحد مجانا ( بما في
                        ذلك
                        ضريبة
                        القيمة المضافة ٥٪)</h6>
                    <h6><router-link to="/login" class="text-primarydark fw-semibold">
                            خروج
                        </router-link></h6>
                    <p class="mb-0">
                        عند النقر على اشتراك، فإنك تكون قد وافقت على بنود الشروط والاحكام التالية: - سعر الخدمة ٢.٢٥
                        درهم في
                        اليوم
                        بعد يوم واحد مجانا ( بما في ذلك ضريبة القيمة المضافة ٥٪) - سيتم خصم قيمة الاشتراك تلقائيا بعد
                        الفترة
                        ...- يمكنك إيقاف الخدمة في أي وقت بإرسال
                        يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي
                        للوصول
                        السريع إلى المحتوى
                    </p>


                </div>
            </div>

        </Form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ["digitCount", "succesmessage"],
    data() {
        return {
            digits: [],
            placeholderText: null,
            digitvale: null,
            phoneNumber: '',
            message: 'تم إرسال رمز التحقق ',
            sucmes: this.succesmessage,
            loadingDiv: false,
            errormessage: '',
            disabledlink: true,
            transactionID: '',
            subservices: '',
            loadingDivnew: false,
            remainingTime: 0,
            countdownInterval: null,
            disablebtn: true,

        };
    },
    mounted() {
        this.phoneNumber = sessionStorage.getItem('registerNumber');
        this.transactionID = sessionStorage.getItem('transaction_id');
        this.subservices = sessionStorage.getItem('subservice_id');
        // console.log(this.phoneNumber)

        // if(this.digits === ''){
        //     this.loadingDiv = true;
        // }
    },
    beforeUnmount() {
        // Clear the interval to prevent memory leaks when the component is destroyed
        clearInterval(this.countdownInterval);
    },
    methods: {
        handleKeyDown(event, index) {
            // console.log('event check', event, index)
            if (
                event.key !== "Tab" &&
                event.key !== "ArrowRight" &&
                event.key !== "ArrowLeft"
            ) {
                event.preventDefault();
            }

            if (event.key === "Backspace") {
                this.digits[index] = null;
                if (index != 0) {
                    // console.log(index);
                    this.$refs.otpCont.children[index - 1].focus();
                    this.$refs.otpCont.children[index].value = "";
                } else {
                    this.digits = [];
                    this.disablebtn = true;
                    return false;
                }
                return;
            }

            if (new RegExp("^([0-9])$").test(event.key)) {
                this.digits[index] = event.key;
                this.$refs.otpCont.children[index].value = this.digits[index];
                if (index != this.digitCount - 1) {
                    this.$refs.otpCont.children[index + 1].focus();
                }
                this.digitvale = this.digits.join("");

            }
            // console.log('yes we do', this.digitvale.length)

            if(this.digitvale.length === 4){
                this.disablebtn = false;
            }
           
        },

        async resendotp() {
            // this.loadingDiv = true;
            this.disabledlink = false;
            this.remainingTime = 60;
            
            this.countdownInterval = setInterval(() => {
                // Decrease remaining time by 1 second
                this.remainingTime--;
                // If the remaining time reaches 0, clear the interval and enable the link
                if (this.remainingTime <= 0) {
                    clearInterval(this.countdownInterval);
                    this.disabledLink = true;
                }
            }, 1000); // Update every second

            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/register-with-number', {
                    phone: this.phoneNumber,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            mode: 'no-cors'
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    this.message = 'إعادة إرسال الرمز';
                    this.errors = '';
                    // this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
                    setTimeout(() => {
                        this.disabledlink = true;
                    }, 60000);
                }

                // if (response.status === 400 || response.status === 401) {
                //   // console.error('Login failed:', response.errors);
                //   this.errors = 'Phone number already taken';
                // }

            } catch (error) {

                console.error('Error during login:', error.response.data);
                this.errors = 'رقم الهاتف مأخوذ بالفعل';
                // setTimeout(() => {
                //     this.disabledlink = true;
                // }, 60000);

            } finally {
                this.loadingDiv = false;
            }
        },

        async pincheck() {
            this.loadingDiv = true;
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/verify-code', {

                    phone: this.phoneNumber,
                    pincode: this.digitvale,
                    transaction_id: this.transactionID,
                    subservice_id: this.subservices

                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            mode: 'no-cors'
                        }
                    });
                if (response.status === 200 || response.status === 201) {

                    this.message = 'لقد تم إرسال بيانات الاعتماد الخاصة بك بنجاح إلى الرقم الذي قدمته';

                    setTimeout(() => {
                        this.loadingDivnew = true;
                    }, 500);

                    setTimeout(() => {
                        this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                    }, 5000);

                }
                if (response.status === 500) {
                    this.errormessage = 'أدخل رقم التعريف الشخصي الصحيح';
                    this.loadingDiv = false;
                }

            } catch (error) {
                this.errormessage = 'أدخل رقم التعريف الشخصي الصحيح';
                console.error('Error during login:', error);
                this.loadingDiv = false;
            }
        }

    },
};
</script>
<style scoped>
.digit-box {
    width: 17%;
    border: none;
    display: inline-block;
    padding: 3px;
    margin: 5px;
    text-align: center;
    font-size: 24px;
    background: rgba(245, 245, 245, 1);
    height: 69px;
    border-radius: 15px;
    font-weight: 700;
    color: rgb(59, 55, 134) !important;
}

.digit-box:focus-visible {
    border: none;
}
</style>